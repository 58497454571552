<template>
  <div class="header navbar-default">
    <div class="navbar-header">
      <b-navbar-brand to="/">e-feed</b-navbar-brand>
      <b-navbar-nav class="d-flex flex-wrap flex-row">
        <b-nav-item to="/feeds"><span class="text-black">Feeds</span></b-nav-item>
      </b-navbar-nav>
    </div>
    <ul class="d-flex flex-grow-1 navbar-nav">
      <li class="flex-grow-1"></li>
      <li class="d-flex align-items-center">
        <span v-if="MandantOptions.length === 0">No mandant available</span>
        <span v-else-if="MandantOptions.length === 1">{{ MandantName }}</span>
        <b-form-select
          v-else
          v-model="mandantCode"
          :options="MandantOptions"
          @change="onMandantSelected"
        ></b-form-select>
      </li>
      <b-nav-item-dropdown class="navbar-user" right>
        <template #button-content>
          <span>{{ AccountName }}</span>
        </template>
        <b-dropdown-item to="/admin" v-if="isAdmin()">Admin</b-dropdown-item>
        <b-dropdown-item to="/signout">Sign out</b-dropdown-item>
      </b-nav-item-dropdown>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  components: {},
  computed: {
    AccountName() {
      return this.account.login;
    },
    MandantName() {
      const currentMandant = this.app.mandantList.find(
        (mandant) => (mandant.code === this.mandantCode),
      );
      return currentMandant ? currentMandant.title : '';
    },
    MandantOptions() {
      return this.app.mandantList.map((mandant) => ({ text: mandant.title, value: mandant.code }));
    },
  },
  data() {
    return {
      account: this.$store.state.account,
      app: this.$store.state.app,
      mandantCode: '',
    };
  },
  methods: {
    onMandantSelected() {
      this.$router.push('/feeds', () => {}, () => {});
      this.$store.commit('setMandantCode', this.mandantCode);
      this.$store.commit('saveMandantCode');
    },
  },
  watch: {
    'app.mandantCode': {
      immediate: true,
      handler(mandantCode) {
        if (mandantCode === this.mandantCode) return;
        this.mandantCode = mandantCode;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.b-nav-dropdown::v-deep {
  & .dropdown-menu-right {
    left: -8px !important;
  }

  & .dropdown-toggle::after {
    vertical-align: middle;
  }
}

.navbar-nav::v-deep .router-link-active {
  font-weight: bold;
}
</style>
